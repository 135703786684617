.swipebody {
  margin-top: 0px;
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-button-prev {
  background-color: #383838;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  font-size: 2px;
  margin-left: 10px;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-next {
  background-color: #383838;
  padding: 10px;
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 20px;
  margin-right: 10px;
}

.swiper-navigation {
  height: 10px;
  width: 10px;
}

.swiper-pagination {
  background-color: #494949;
  align-items: center;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  position: absolute !important;
  bottom: 0 !important;
  height: 30px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.swiper-pagination-bullet {
  background-color: #e8e8e8 !important;
  opacity: 1;
  width: 8px;
  height: 4px;
  border-radius: 4px;
  transition: width 0.2s;
}
.swiper-pagination-bullet-active {
  background-color: #23ce6b !important;
  width: 14px;
  height: 4px;
  border-radius: 4px;
}

.popup-overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
  backdrop-filter: blur(10px);
}
