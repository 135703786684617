@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  @apply font-Manrope;
}

.input:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 10px #719ece;
}

p {
  font-size: 16;
  line-height: "24px";
  color: #494949;
}

h1 {
  font-size: 72px;
  font-weight: 700;
  @apply font-DMSans;
}

h2 {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: -0.04em;
  @apply font-DMSans;
}

h3 {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #1c1c1c;
  font-weight: 700;

  @apply font-DMSans;
}

h5 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  text-align: center;

  font-weight: 700;

  @apply font-DMSans;
}

h6 {
  color: #23ce6b;

  @apply font-DMSans;
}

@media screen and (min-width: 375px) {
  h3 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #1c1c1c;
    font-weight: 700;

    @apply font-DMSans;
  }
}

@media screen and (min-width: 744px) {
  h3 {
    font-size: 46px;
    line-height: 54px;
  }

  h5 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media screen and (min-width: 1260px) {
  h3 {
    font-size: 72px;
    line-height: 76px;
  }

  h5 {
    font-size: 46px;
    line-height: 60px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
